  .not-found-container {
    background-color: #f5f5dc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .not-found-logo {
    width: 150px; /* Adjust the size as needed */
    margin-bottom: 20px;
  }
  
  h2 {
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-size: 22px;
  }
  
  .login-link {
    margin-top: 5px;
    font-size: 18px;
    color: black;
    text-decoration: none;
    font-weight: bold;
  }
  
  .login-link:hover {
    text-decoration: underline;
  }
  