
.view-my-pet-container {
    background-color: #f5f5dc; /* Background color */
    color: #353634; /* Text color */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 110vh;
    font-family: Arial, sans-serif;
  }
  
  .view-my-pet-logo {
    width: 140px;
    margin-bottom: 20px;
  }
  
  
  .pet-info {
    background-color: black !important; /* Info container background color */
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .owner-info, .pet-info, .extras {
    background-color: #B4D479;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .owner-info h2, .pet-info h2, .extras h2 {
    color: black; /* Section title color */
    margin-bottom: 10px;
  }
  
  .pet-info p, .owner-info p, .extras p {
    margin: 10px 0;
    font-size: 16px;
  }
  
  .pet-info strong, .owner-info strong, .extras strong {
    color: #353634; /* Strong text color */
  }
  
  .pet-photo {
    max-width: 100%;
    border-radius: 10px;
    margin-top: 10px;
  }
  
  /* Responsive design */
  @media (max-width: 600px) {
    .view-my-pet-container {
      padding: 10px;
    }
  
    .pet-info {
      padding: 15px;
    }
  
    .pet-info p, .owner-info p, .extras p {
      font-size: 14px;
    }
  }
  