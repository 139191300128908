body, html, #root {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.create-account-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px; 
  background-color: #f5f5dc;
}

.create-title {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 24px;
  color: #353634;
  margin-bottom: 10px; 
}

.create-account-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  color: #000;
  max-width: 100%;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  margin-bottom: 10px; 
  font-size: 1.8em; 
  color: #353634;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 15px; 
}

.input-container {
  width: 100%;
  margin-bottom: 10px; 
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #353634;
}

.required {
  color: red;
  margin-left: 5px;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 1em;
}

input:focus {
  border-color: #B4D479;
  box-shadow: 0 0 5px rgba(185, 240, 127, 0.5);
  outline: none;
}

button {
  background-color: #B4D479;
  color: #353634;
  padding: 12px; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-top: 15px; 
}

button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #476569;
  color: white;
}

.password-strength {
  margin-top: 5px; 
  font-weight: bold;
  font-size: 0.9em; 
}

.password-strength.weak {
  color: red;
}

.password-strength.moderate {
  color: orange;
}

.password-strength.strong {
  color: green;
}

.phone-number {
  margin-top: 15px;
}

.back-to-login {
  margin-top: 15px; 
}

.back-link {
  text-decoration: none;
  color: #353634;
  font-weight: bold;
}

.arrow {
  font-size: 1.2em;
  margin-right: 5px;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}

@media (max-width: 600px) {
  .create-account-form {
    padding: 20px; 
  }

  h1 {
    font-size: 1.5em; 
  }

  button {
    padding: 10px;
  }

  .create-title {
    font-size: 22px;
  }
}
