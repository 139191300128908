body, html, #root {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5dc;
  padding: 20px; 
}

.login-title {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-size: 26px;
}

.login-form {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  color: #000;
  max-width: 350px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.logo {
  width: 100px; 
  margin-bottom: 20px;
}

h1 {
  margin-bottom: 15px;
  font-size: 2em;
  color: #353634;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-container {
  width: 100%;
  margin-bottom: 15px; 
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #353634;
}

input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  font-size: 1em;
}

input:focus {
  border-color: #B4D479;
  box-shadow: 0 0 5px rgba(185, 240, 127, 0.5);
  outline: none;
}

button {
  background-color: #B4D479;
  color: #353634;
  padding: 12px 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  transition: background-color 0.3s ease;
  margin-top: 15px; 
}

.signup-link {
  margin-top: 10px;
  font-size: 0.9em; 
}

.signup-link a {
  text-decoration: none;
  color: #353634;
  font-weight: bold;
}

.signup-link a:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 5px;
}

@media (max-width: 480px) {
  .login-container {
      padding: 15px; 
  }

  .login-form {
      padding: 20px; 
      box-shadow: none; 
      border: 1px solid #ccc; 
  }

  .login-title {
      font-size: 22px; 
  }

  .input-container {
      margin-bottom: 10px; 
  }

  h1 {
      font-size: 1.4em; 
  }

  button {
      font-size: 0.9em; 
      padding: 10px 15px; 
  }

  .signup-link {
      margin-top: 8px;
  }
}
